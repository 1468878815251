import React from "react";
import img01 from "../../assets/img/team/team_img01.png";
import img02 from "../../assets/img/team/team_img02.png";
import img03 from "../../assets/img/team/team_img03.png";
import img04 from "../../assets/img/team/team_img04.png";
import img05 from "../../assets/img/team/team_img05.png";
import img06 from "../../assets/img/team/team_img06.png";
import img07 from "../../assets/img/team/team_img07.png";
import img08 from "../../assets/img/team/team_img08.png";
import img09 from "../../assets/img/team/team_img09.png";
import TeamOneItem from "./TeamOneItem";

const TeamOne = () => {
  const team_members = [
    {
      src: img01,
      name: "Felipe Fatique",
      designation: "Founder & CO",
      twitter: "https://twitter.com/f_fatique",
      linkedin: "https://www.linkedin.com/in/felipe-fatique/",
    },
    {
      src: img02,
      name: "Kledson Soares",
      designation: "Co-Founder & CTO",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://linkedin.com/in/kledsonsoares",
    },
    {
      src: img03,
      name: "Vinicius Bedum",
      designation: "Co-Founder & CMO",
      twitter: "https://twitter.com/viniciusbedum",
      linkedin: "https://www.linkedin.com/in/viniciusbedum/",
    },
    {
      src: img04,
      name: "Vitor Lopes",
      designation: "Game Developer",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://linkedin.com/in/josé-vítor-lima-lopes-16195b273",
    },
    {
      src: img05,
      name: "Pedro Moura ",
      designation: "Web Developer",
      twitter: "https://www.linkedin.com/in/peedromoura/",
      linkedin: "https://twitter.com/_bepobepo",
    },
    {
      src: img07,
      name: "Emerson Castro",
      designation: "Visual Artist & Design",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://www.linkedin.com/company/kuma-games-io",
    },
    {
      src: img08,
      name: "Deyse Emanuely",
      designation: "UX Designer",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://www.linkedin.com/company/kuma-games-io",
    },
    {
      src: img09,
      name: "Carlos Loredo",
      designation: "Co-Founder",
      twitter: "https://twitter.com/kumagamesio",
      linkedin: "https://www.linkedin.com/in/carlos-loredo-2b9991264/",
    },
  ];

  return (
    <section id="team" className="team-area pt-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-70">
              <span className="sub-title">Our Team</span>
              <h2 className="title">
                {/* The Leadership <br /> <span>Team</span> */}
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((member, index) => (
            <div key={index} className="col-xl-3 col-md-4 col-sm-6">
              <TeamOneItem item={member} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
